import * as React from "react";
import Layout from "../../../../components/layout/layout";
import {Curiosity, ProductPageProps} from "../../../../typings/page.types";
import { graphql } from "gatsby";
import { Curiosities } from "../../../../components/curiosities/curiosities";
import { Breadcrumb } from "../../../../components/breadcrumb/breadcrumb";
import Container from "../../../../components/container/container";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import Heading from "../../../../components/basic-components/heading/heading";
import classNames from "classnames";
import { Level } from "react-accessible-headings";
import {Helmet} from "react-helmet";


const QA = (curiosities: Curiosity[]) => {
  let txt = "";
  const regex = /(<([^>]+)>)/gi;
  curiosities.forEach((curiosity) => {
    txt += `{
        "@type": "Question",
        "name": "${curiosity.title}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${curiosity.text.replace(regex, "")}"
        }
      },`;
  });
  return txt.replace(/,\s*$/, "");
};
const CuriositiesPage: React.FC<ProductPageProps> = ({
  data: { productsJson },
  location,
}: ProductPageProps) => {
  
    let description;
      
      if(productsJson.slug === "bisolnatur")
        description = "Bisolnatur hakkında, öksürüğünüz olduğunda yapmanız gerekenlerden çocukların öksürük şurubu kullanımına kadar aklınıza takılan tüm sorulara yanıt bulabilirsiniz.";
      else if(productsJson.slug === "bisolduo-surup")
        description ="Bisolduo Şurup hakkında, öksürüğünüz olduğunda yapmanız gerekenlerden çocukların öksürük şurubu kullanımına kadar aklınıza takılan tüm sorulara yanıt bulabilirsiniz."
      else if(productsJson.slug === "bisolduo-sprey")
        description ="Bisolduo Sprey hakkında, öksürüğünüz olduğunda yapmanız gerekenlerden çocukların öksürük şurubu kullanımına kadar aklınıza takılan tüm sorulara yanıt bulabilirsiniz."
      else 
        description = "Bisolnatur hakkında, öksürüğünüz olduğunda yapmanız gerekenlerden çocukların öksürük şurubu kullanımına kadar aklınıza takılan tüm sorulara yanıt bulabilirsiniz";
      

  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: productsJson.seoTitle + " Hakkında Merak Edilenler",
        description:description
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Ürünler","item": "https://www.bisolnatur.com.tr/urunler/"},
                  {"@type": "ListItem","position": 3,"name": "${productsJson.title}","item": "https://www.bisolnatur.com.tr/urunler/${productsJson.slug}/"},
                  {"@type": "ListItem","position": 4,"name": "Merak Edilenler","item": "https://www.bisolnatur.com.tr/urunler/${productsJson.slug}/merak-edilenler/"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "headline": "Öksürüğünü Rahatlat",
              "name" : "${productsJson.seoTitle} Hakkında Merak Edilenler",
              "description": ${description}
              "url": "https://www.bisolnatur.com.tr${location.pathname}",
              "image": [
              "https://www.bisolnatur.com.tr/kuru-neden.png"
              ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [${QA(productsJson.curiosities)}]
            }
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
      
        <div className={classNames("flexbox", styles.header)}>
          {productsJson.slug === "bisolnatur" ? (
            <StaticImage
              className={styles.image}
              src="../../../../images/Content-image@3x.jpg"
              alt="Hapşuran çocuk görseli"
              width={1680}
              height={450}
            />
          ) : (
            <StaticImage
              className={styles.image}
              src="../../../../images/Content-image-bisolduo.png"
              alt="Öksüren Kadın görseli"
              width={1680}
              height={450}
            />
          )}
          <div className={styles.logoHolder}>
            <img
              src={productsJson.logo.publicURL}
              alt="Logo"
              width={142}
              height={50}
            />
          </div>
          <div className="break" />
          <Heading>Merak Edilenler</Heading>
        </div>
      </Container>
      <Container>
        <div className={classNames(productsJson.slug, styles.holder)}>
          <Curiosities item={productsJson} />
        </div>
      </Container>
    </Layout>
  );
};
export default CuriositiesPage;

export const query = graphql`
  query ($id: String!) {
    productsJson(id: { eq: $id }) {
      className
      curiosities {
        title
        text
      }
      logo {
        publicURL
      }
      seoDescription
      seoTitle
      slug
    }
  }
`;
